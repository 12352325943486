import { clear, filter, refreshIcon, search, file_download } from "assets/media";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ProgressBar
} from "common/components";
import AvatarCellRenderer from "common/components/custom/AvatarCellRenderer/AvatarCellRenderer";
import GPOAgGrid from "common/components/custom/GPOAgGrid/GPOAgGrid";
import GridDateCellRenderer from "common/components/custom/GridDateCellRenderer/GridDateCellRenderer";
import MoreActionsDocumentCellRenderer from "common/components/custom/MoreActionsDocumentCellRenderer/MoreActionsDocumentCellRenderer";
import StatusCellRenderer from "common/components/custom/StatusCellRenderer/StatusCellRenderer";
import { ApiStatus } from "common/enums";
import { TABLE_SORT_ORDER } from "common/enums/table-sort-order.enum";
import { useDebounce, useInterval } from "common/hooks";
import { DateValue } from "common/models/date-range.model";
import { GridSortModel } from "common/models/grid-sort-model";
import {
  RootState,
  useAppDispatch,
  useAppSelector
} from "common/redux/core/root.reducer";
import { DateUtil, FileUtil } from "common/utils";
import { DocumentTableOptions } from "modules/gpo-agent/enums/document-types.enum";
import { ManagedDocumentsFilterTypes } from "modules/gpo-agent/enums/managed-documents-filter-types.enum";
import { ManagedDocumentsType } from "modules/gpo-agent/enums/managed-documents-type.enum";
import { QuestionDetailFilterTypes } from "modules/gpo-agent/enums/question-details-filter.enum";
import { IChatHistoryMessage } from "modules/gpo-agent/models/chat-history/chat-history-messages.model";
import { IDocumentDetails } from "modules/gpo-agent/models/chat-history/reference-documents-response.model";
import {
  ManagedDocumentsFilter,
  ManagedDocumentsRequest,
  SortOrder
} from "modules/gpo-agent/models/filter-component/filter-values.model";
import { agentChatHistoryActions } from "modules/gpo-agent/redux/agent-chat-history";
import { agentsActions } from "modules/gpo-agent/redux/agents";
import { appHeaderActions } from "modules/gpo-agent/redux/header";
import moment from "moment";
import React, { useEffect, useState } from "react";
import AddDocument from "../AddDocument/AddDocument";
import AddDocumentVersion from "../AddDocumentVersion/AddDocumentVersion";
import DocumentTableActions from "../DocumentTableActions/DocumentTableActions";
import ManageDocumentsFilter from "../ManagementDocumentsFilter/ManageDocumentsFilter";
import PDFViewer from "../PDFViewer/PDFViewer";
import "./ManageDocuments.scss";
import { ExportManagedDocumentDetails } from "modules/gpo-agent/models/manage-document-filter/export-managed-document.model";
import { AUTO_REFRESH_INTERVAL_MS } from "common/config/app.config";
const ManageDocuments: React.FC = () => {
  const [showDocumentDropdown, setShowDocumentDropdown] =
    useState<boolean>(false);
  const [showSelectedRowInfo, setShowSelectedRowInfo] =
    useState<boolean>(false);
  const [activeScreenTab, setActiveScreenTab] = useState<string>("tab1");
  const [activeTab, setActiveTab] = useState<string>("tab1");
  const [chatHistoryMessages, setChatHistoryMessages] = useState<
    Array<IChatHistoryMessage>
  >([]);
  const [selectedRowInfo, setSelectedRowInfo] = useState<any>();
  const [columnDef, setColumnDef] = useState<Array<any>>([]);
  const uploadDocument = useAppSelector(
    (state) => state.agentChatHistory.uploadDocument
  );
  const [topValue, setTopValue] = useState<any>();

  const handleTab = (tab: string) => {
    setActiveTab(tab);
  };

  const handleScreenTab = (tab: string) => {
    setActiveScreenTab(tab);
    setTabSwitchFilter([...appliedFilter]);
    if (!tabSwitchFilter?.length && tab == "tab2") {
      setAppliedFilter([
        {
          property: ManagedDocumentsFilterTypes.ISARCHIVE,
          type: ManagedDocumentsType?.NONE,
          value: ["true"]
        },
        {
          property: ManagedDocumentsFilterTypes.COUNTRY,
          type: ManagedDocumentsType.TEXT,
          value: ["All"]
        }
      ]);
    } else {
      setAppliedFilter([...tabSwitchFilter]);
    }
  };

  const DEBOUNCE_TIME_OUT = 1000;
  const [showFilter, setShowFilter] = useState(false);
  const [showDocumentGridOptions, setShowDocumentGridOptions] =
    useState<boolean>(false);
  const initialFilter: Array<ManagedDocumentsFilter> = [
    {
      property: ManagedDocumentsFilterTypes.COUNTRY,
      type: ManagedDocumentsType.TEXT,
      value: ["All"]
    },
    {
      property: "isArchived",
      type: ManagedDocumentsType?.NONE,
      value: ["false"]
    }
  ];
  const [appliedFilter, setAppliedFilter] =
    useState<Array<ManagedDocumentsFilter>>(initialFilter);
  const [tabSwitchFilter, setTabSwitchFilter] = useState<
    Array<ManagedDocumentsFilter>
  >([]);
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [filterRequest, setFilterRequest] = useState<
    ManagedDocumentsFilter | {}
  >({});
  const [selectedRowDetails, setSelectedRowDetails] = useState<{
    comment: string;
    userName: string;
  }>();
  const debouncedSearchText: string = useDebounce(
    searchText,
    DEBOUNCE_TIME_OUT
  );
  const [addDocumentType, setAddDocumentType] = useState<
    "new" | "version" | ""
  >("");
  const [totalNonArchived, setTotalNonArchived] = useState<number>(0);
  const [totalArchived, setTotalArchived] = useState<number>(0);
  const [currentGridPageNumber, setCurrentGridPageNumber] = useState<number>(0);
  const [currentGridPageSize, setCurrentGridPageSize] = useState<number>(10);
  const [documentDetails, setDocumentDetails] = useState<IDocumentDetails>();
  const countryState = useAppSelector(
    (state: RootState) => state.header?.countryResponse?.data
  );
  const archivedCount = useAppSelector(
    (state: RootState) => state.agents?.totalArchivedCount
  );
  const [showExportConfirmation, setShowExportConfirmation] = useState(false);
  const [showExportProgress, setShowExportProgress] = useState(false);
  const [refreshGridTrigger, setRefreshGridTrigger] = useState(0);
  const exportedManagedDocumentDetails = useAppSelector(
    (state: RootState) => state.header.exportedManagedDocumentDetails);
  const [lastUpdatedDatetime, setLastUpdatedDatetime] = useState(DateUtil.getTimeInDDMMMYYYY());

  const exportedQuestionDetailsData = useAppSelector(
    (state: RootState) => state.agents.exportedQuestionDetailsData
  );
  const [gridSortParams, setGridSortParams] = useState<GridSortModel>({
    colId: "createdOn",
    sort: TABLE_SORT_ORDER.DESCENDING
  });

  useEffect(() => {
    dispatch(appHeaderActions.getAllCountries());
    dispatch(appHeaderActions.getAllDocumentAdmins());
    dispatch(appHeaderActions.getAllDocumentStatuses());

    const request: ManagedDocumentsRequest = {
      filters: [
        {
          property: "isArchived",
          type: ManagedDocumentsType?.NONE,
          value: ["true"]
        }
      ]
    };
    dispatch(agentsActions.getTotalArchived(request));
  }, []);

  useEffect(() => {
    if (uploadDocument.status === ApiStatus.SUCCESS) {
      setAddDocumentType("");
      setRefreshGridTrigger((prev) => prev + 1);
      dispatch(agentChatHistoryActions.resetUploadDocument());
    }
  }, [uploadDocument.status]);

  useEffect(() => {
    if (archivedCount) {
      if (archivedCount?.totalResults) {
        setTotalArchived(archivedCount.totalResults);
      }
    }
  }, [archivedCount]);

  useEffect(() => {
    setSearchFilter(debouncedSearchText);
  }, [debouncedSearchText]);

  useEffect(() => {
    setFilterRequest({
      filters:
        appliedFilter && appliedFilter?.length ? getFilterRequestBody() : []
    });
  }, [appliedFilter, searchFilter]);

  
  useEffect(() => {
    if (exportedManagedDocumentDetails) {
      const dataToCSV = exportedManagedDocumentDetails;
      const fileName = `Managed Documents_${moment().format(
        "YYYYMMDDHHmm"
      )}.csv`;
      FileUtil.stringToFile(dataToCSV, fileName, "text/csv");
      setShowExportProgress(false);
      dispatch(appHeaderActions?.resetExportDocument());
    }
  }, [exportedManagedDocumentDetails]);

  useEffect(() => {
    if (countryState?.code == 200) {
      if (countryState?.countries?.length) {
        const columnDef = [
          {
            field: "documentName",
            headerName: "DOCUMENT NAME",
            sortable: true,
            unSortIcon: true,
            flex: 3,
            cellRendererFramework: (params) => {
              return (
                <span className="grid-data" title={params?.value}>
                  {params?.value}
                </span>
              );
            }
          },
          {
            field: "version",
            headerName: "VERSION",
            sortable: true,
            unSortIcon: true,
            flex: 2,
            cellRendererFramework: (params) => {
              return (
                <span
                  className="grid-data"
                  title={params?.data?.displayVersion}
                >
                  {params?.data?.displayVersion}
                </span>
              );
            }
          },
          {
            field: "countries",
            headerName: "COUNTRY",
            sortable: false,
            unSortIcon: true,
            flex: 2,
            cellRendererFramework: (params) => {
              return (
                <span
                  className="grid-data"
                  title={params?.value?.map((item) => item?.name)?.toString()}
                >
                  {params?.value?.map((item) => item?.name).toString()}
                </span>
              );
            }
          },
          {
            field: "createdOn",
            headerName: "UPLOADED ON",
            sortable: true,
            unSortIcon: true,
            flex: 2,
            cellRendererFramework: (params) => (
              <GridDateCellRenderer
                dateToConvert={params?.value}
                format={"DD MMM, YYYY"}
              />
            )
          },
          {
            field: "status",
            headerName: "STATUS",
            sortable: true,
            unSortIcon: true,
            flex: 2,
            cellRendererFramework: (params) => {
              return (
                <StatusCellRenderer
                  statusMapper={[
                    {
                      value: "Ingested successfully",
                      displayName: "Ingested successfully",
                      color: "#34C768"
                    },
                    {
                      value: "In progress",
                      displayName: "In progress",
                      color: "#FF9831"
                    },
                    {
                      value: "Error",
                      displayName: "Error",
                      color: "#FF4236"
                    }
                  ]}
                  statusValue={params?.value}
                ></StatusCellRenderer>
              );
            }
          },
          {
            field: "createdBy",
            headerName: "UPLOADED BY",
            sortable: true,
            unSortIcon: true,
            flex: 2,
            cellRendererFramework: (params) => (
              <AvatarCellRenderer
                profileIdField={params?.data?.userGuid}
                displayName={params?.value}
              />
            )
          },
          {
            field: "guidCode",
            headerName: "",
            flex: 1,
            cellRendererFramework: (params) => {
              return (
                <MoreActionsDocumentCellRenderer
                  rowData={params?.data}
                  onActionClicked={handleMoreActionsClicked}
                />
              );
            }
          }
        ];
        setColumnDef(columnDef);
      }
    }
  }, [countryState]);

  const handleApplyFilter = (filter: Array<ManagedDocumentsFilter>) => {
    //call api
    setAppliedFilter(structuredClone(filter));
    setShowFilter(false);
  };

  const formatDate = (utcDate: string) => {
    const date = new Date(utcDate);
    //const newDate = new Date(date.getTime() - date.getTimezoneOffset()*60*1000);
    return moment(date).format("D/M/YYYY");
     //return moment.utc(utcDate).format("D/M/YYYY");
  };

  const handleResetFilter = () => {
    setAppliedFilter([]);
  };

  const handleClearSelectedFilter = (
    key: ManagedDocumentsFilterTypes,
    keyvalue: any
  ) => {
    const newFilter = appliedFilter
      .map((item) => {
        if (item.property != key) {
          return item;
        } else {
          if (key != ManagedDocumentsFilterTypes?.UPDATED_ON) {
            const existingValue = item.value;
            const newValues = existingValue?.filter((a) => a != keyvalue);
            return {
              ...item,
              value: newValues
            };
          } else {
            const existingValue = item.value;
            const newValues = existingValue?.filter(
              (a) => !keyvalue?.includes(a)
            );
            return {
              ...item,
              value: newValues
            };
          }
        }
      })
      ?.filter((data) => data?.value?.length);

    setAppliedFilter(newFilter);
  };

  const handleMoreActionsClicked = (event: any, data: any, action: any) => {
    setShowDocumentGridOptions(true);
    setSelectedRowInfo(data);

    if (event?.clientY) {
      const topValue = event?.clientY + 20;
      setTopValue(topValue + "px");
    }
  };

  const getFilterElements = (key: string) => {
    const selectedFilter = appliedFilter?.filter(
      (filter) => filter.property == key
    );
    switch (key) {
      case ManagedDocumentsFilterTypes.COUNTRY:
        if (selectedFilter && selectedFilter?.length) {
          if (selectedFilter[0]?.value?.includes("All")) {
            return (
              <div className="user-review__chip">
                <p>{"All Countries"}</p>
                <img
                  src={clear}
                  alt="clear"
                  onClick={() =>
                    handleClearSelectedFilter(
                      ManagedDocumentsFilterTypes.COUNTRY,
                      "All"
                    )
                  }
                />
              </div>
            );
          } else {
            return selectedFilter[0]?.value?.map((item) => (
              <div className="user-review__chip">
                <p>{item}</p>
                <img
                  src={clear}
                  alt="clear"
                  onClick={() =>
                    handleClearSelectedFilter(
                      ManagedDocumentsFilterTypes.COUNTRY,
                      item
                    )
                  }
                />
              </div>
            ));
          }
        }
        break;
      case ManagedDocumentsFilterTypes.STATUS:
        if (selectedFilter && selectedFilter?.length) {
          return selectedFilter[0]?.value?.map((item) => (
            <div className="user-review__chip">
              <p>{item}</p>
              <img
                src={clear}
                alt="clear"
                onClick={() =>
                  handleClearSelectedFilter(
                    ManagedDocumentsFilterTypes.STATUS,
                    item
                  )
                }
              />
            </div>
          ));
        }
        break;
      case ManagedDocumentsFilterTypes.UPLOADED_BY:
        if (selectedFilter && selectedFilter?.length) {
          return selectedFilter[0]?.value?.map((item) => (
            <div className="user-review__chip">
              <p>{item}</p>
              <img
                src={clear}
                alt="clear"
                onClick={() =>
                  handleClearSelectedFilter(
                    ManagedDocumentsFilterTypes.UPLOADED_BY,
                    item
                  )
                }
              />
            </div>
          ));
        }
        break;
      case ManagedDocumentsFilterTypes.UPDATED_ON:
        if (selectedFilter && selectedFilter?.length) {
          return (
            <div className="user-review__chip">
              <p>
                {formatDate(selectedFilter[0]?.value[0])} -{" "}
                {formatDate(selectedFilter[0]?.value[1])}
              </p>
              <img
                src={clear}
                alt="clear"
                onClick={() => {
                  handleClearSelectedFilter(
                    ManagedDocumentsFilterTypes.UPDATED_ON,
                    selectedFilter[0]?.value
                  );
                }}
              />
            </div>
          );
        }
        break;
    }

    return <></>;
  };

  const getISODate = (date: DateValue) => {
    if (date) {
      // const iso = date?.toISOString();
      return date?.toISOString();
      // const utcDate = moment(date).utcOffset(0);
      // utcDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      // return utcDate?.toISOString();

      
    }
  };

  const getFilterRequestBody = () => {
    if (appliedFilter && appliedFilter?.length) {
      const newFilter = appliedFilter?.filter((item, i) => {
        if (item?.property == ManagedDocumentsFilterTypes.COUNTRY) {
          const existingValue = item.value;
          const newValues = existingValue?.filter((a) => a != "All");

          const newItem = { ...item, value: newValues };
          if (newValues?.length) {
            return newItem;
          }
        } 
        else if (item.property == ManagedDocumentsFilterTypes.UPDATED_ON) {
          const existingValue = item.value;
          const newItem = {
            ...item,
            value: [
              getISODate(new Date(existingValue[0])),
              getISODate(new Date(existingValue[1]))
            ]
          };
          return newItem;
        } else {
          return item;
        }
      });
      return newFilter;
    } else {
      return [];
    }
  };

  const onRowClicked = (rowData: any, rowIndex: number) => {
    setShowSelectedRowInfo(true);
    setSelectedRowInfo(rowData);
    const activeRow = document.querySelector(
      `.manage-documents__table .ag-root-wrapper .ag-row.selectedRow`
    );
    if (activeRow) {
      activeRow?.classList?.remove("selectedRow");
    }
    const selectedRow = document.querySelector(
      '.manage-documents__table .ag-root-wrapper .ag-center-cols-container div.ag-row[row-index="' +
        rowIndex +
        '"]'
    );
    if (selectedRow) {
      selectedRow?.classList?.add("selectedRow");
    }
  };

    const exportTable = () => {
        const request:ExportManagedDocumentDetails={
          query:{
            pageNumber: currentGridPageNumber,
            pageSize: currentGridPageSize,
            sortColumn: gridSortParams.colId,
            sortOrder: SortOrder[gridSortParams.sort],
            searchText: searchFilter?searchFilter:"",
            filters: appliedFilter && appliedFilter?.length ? getFilterRequestBody() : []
          }
        }
        dispatch(appHeaderActions?.exportDocument(request));
        setShowExportProgress(true);
      
    };
  
    const handleExport = async () => {
      setShowExportConfirmation(false);
      exportTable();
    };
  
    const handleExportIconClick = () => {
      if (appliedFilter || searchFilter.length > 0) {
        setShowExportConfirmation(true);
      } else {
        handleExport();
      }
    };
  const onArchivedCompleted = () => {
    setAppliedFilter([...appliedFilter]);
  };

  const handleTableOptionClick = (option: DocumentTableOptions) => {
    switch (option) {
      case DocumentTableOptions.NewVersion:
        setAddDocumentType("version");
        break;
    }
  };

  /* Auto Refresh */
  const handleAutoRefresh = () => {
    setRefreshGridTrigger((prev) => prev + 1);
  };
    const { startInterval } = useInterval(
      handleAutoRefresh,
      AUTO_REFRESH_INTERVAL_MS,
      false
    );

    const handleGridApiSuccess = () =>{
      setLastUpdatedDatetime(DateUtil.getTimeInDDMMMYYYY());
      startInterval();
    }
  /* Auto Refresh */

  return (
    <div className="manage-documents">
      <div className="manage-documents__container">
        <div className="manage-documents__header">
          <div className="manage-documents__header__left">
            <h2>Manage Documents</h2>
            <p>{`Last updated on ${lastUpdatedDatetime}`}</p>
          </div>
          <div
            className="manage-documents__header__right"
            onClick={() => setAddDocumentType("new")}
          >
            <span>Add Document</span>
          </div>
        </div>

        <div className="manage-documents__body">
          <div className="manage-documents__tabs">
            <p
              className={activeScreenTab === "tab1" ? "active" : ""}
              onClick={(event) => handleScreenTab("tab1")}
            >
              Active Documents
              <span>{totalNonArchived}</span>
            </p>
            <p
              className={activeScreenTab === "tab2" ? "active" : ""}
              onClick={(event) => handleScreenTab("tab2")}
            >
              Archived Documents
              <span>{totalArchived}</span>
            </p>
          </div>
          {showFilter && (
            <ManageDocumentsFilter
              appliedFilters={appliedFilter}
              onCloseModal={() => setShowFilter(false)}
              onApply={handleApplyFilter}
              onReset={handleResetFilter}
            />
          )}
          {showDocumentGridOptions && (
            <DocumentTableActions
              topValue={topValue}
              rowInfo={selectedRowInfo}
              onCloseModal={setShowDocumentGridOptions}
              onArchivedCompleted={onArchivedCompleted}
              onOptionClick={handleTableOptionClick}
            />
          )}
          {appliedFilter && (
            <div className="question-details__selected-filter">
              <div  className="question-details__selected-filter__wrapper">
              <p className="question-details__selected-filter__label">
                Selected filters
              </p>
              {appliedFilter.map((key) => getFilterElements(key?.property))}
              <p
                className="question-details__selected-filter__clear"
                onClick={handleResetFilter}
              >
                Clear all
              </p>
              </div>
              <button
                  className="question-details__selected-filter__refresh-btn"
                  onClick={handleAutoRefresh}
                >
                  <img src={refreshIcon} />
                  <p>Refresh</p>
                </button>
            </div>
          )}
          {documentDetails && (
            <PDFViewer
              documentDetails={documentDetails}
              onPDFClose={setDocumentDetails}
            />
          )}

          <div className="manage-documents__action-panel">
            <div className="manage-documents__action-panel__search">
              <img src={search} />
              <input
                type="text"
                placeholder=""
                onChange={(evt) => setSearchText(evt.target.value)}
              />
            </div>
            <div className="manage-documents__action-panel__right">
              <div
                className="manage-documents__action-panel__filter-btn"
                onClick={() => setShowFilter(true)}
              >
                <img src={filter} />
                <span>Filter</span>
              </div>
              {currentGridPageNumber!=0 && (
                <button
                  className="manage-documents__action-panel__export-btn"
                  onClick={() => handleExportIconClick()}
                >
                  <img src={file_download} />
                  <p>Export</p>
                </button>
                )}
            </div>
          </div>

          <div className="manage-documents__table">
            <GPOAgGrid
              method="POST"
              url={"documents/managed"}
              paginationSize={10}
              columnDefinitions={columnDef}
              requestBody={filterRequest}
              responsePropertyKey={""}
              searchFilter={searchFilter}
              payloadType="body"
              refreshGrid={refreshGridTrigger}
              initialSortModel={gridSortParams}
              onRowClicked={onRowClicked}
              onSort={setGridSortParams}
              onTotalResults={
                activeScreenTab == "tab1"
                  ? setTotalNonArchived
                  : setTotalArchived
              }
              onCurrentPageNumber={
                setCurrentGridPageNumber
              }
              onCurrentPageSize={
                setCurrentGridPageSize
              }
              onGridApiSuccess={handleGridApiSuccess}
            />
          </div>
        </div>
      </div>
      {showExportConfirmation && (
        <Modal>
          <ModalHeader onClose={() => setShowExportConfirmation(false)}>
            Export Confirmation
          </ModalHeader>
          <ModalBody>
            The export file is based on the filters applied. Do you want to
            continue ?
          </ModalBody>
          <ModalFooter>
            <Button variant="primary" onClick={handleExport}>
              Yes
            </Button>
            <Button
              variant="secondary"
              onClick={() => setShowExportConfirmation(false)}
            >
              No
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {showExportProgress && (
        <Modal>
          <ModalBody className="user-review__export-progress">
            <ProgressBar label="Export is being generated. Please wait before taking any other action..." />
          </ModalBody>
        </Modal>
      )}
      {addDocumentType == "new" && (
        <AddDocument onClose={() => setAddDocumentType("")} />
      )}
      {addDocumentType == "version" && (
        <AddDocumentVersion
          existingDocument={{
            id: selectedRowInfo?.guidCode,
            name: selectedRowInfo?.documentName
          }}
          onClose={() => setAddDocumentType("")}
        />
      )}
    </div>
  );
};
export default ManageDocuments;
