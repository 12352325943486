// Dev config
// export const ENV_CONFIG = {
//    CLIENT_ID: 'bccdc48a-8943-4326-86f0-b62b1403155d',
//    AUTHORITY: 'https://login.microsoftonline.com/ey.com/',
//    REDIRECT_URI: "https://localhost:3000",
//    CACHE_LOCATION: 'sessionStorage',
//    DOMAIN : 'ey.com',
//    API_SCOPE:'api://e4b17bed-b45f-47bf-88a1-71bb5d1eb45b/user_impersonation',
//    API_URI:'https://usedgpowap04.azurewebsites.net/api/',
//    APP_IDLE_TIME_MIN: 10,
//    AUTO_REFRESH_INTERVAL_MS: 300000,
//    AUTHORIZATION_ACCESS_REQUEST_DATA_AGENT_PORTAL:"mailto:rajagopal.pallath@gds.ey.com?cc=bibitha.bahuleyan@gds.ey.com;prasanth.christurajan@gds.ey.com&subject=Request for Access to GPO Agent Portal&body=Dear Admin,%0AKindly provide access to the application."
//    //mail to can be configured as follows:
//    //"mailto:xyz@mail.com;abc@mail.com?cc=abc1@mail.com;abc2@mail.com&bcc=abc1@mail.com&subject=Subject here&body=Body-goes-here"
// };

//Prod config
 export const ENV_CONFIG = {
     CLIENT_ID: '5df23587-9453-4a19-b77a-4202ba88709c',
     AUTHORITY: 'https://login.microsoftonline.com/90b92f5f-79c4-4fa9-9eea-7265479e6271/',
     REDIRECT_URI: 'https://gpo-msft-pilot-agentportal.ey.com/',
     CACHE_LOCATION: 'sessionStorage',
     DOMAIN: 'ey.com',
     API_SCOPE: 'api://96be5ce8-33ac-489c-9076-da59bc088ebc/user_impersonator',
     API_URI: 'https://usedgpolwap02.azurewebsites.net/api/',
     APP_IDLE_TIME_MIN: '',
     AUTO_REFRESH_INTERVAL_MS: '#{AUTO_REFRESH_INTERVAL_MS}',
     AUTHORIZATION_ACCESS_REQUEST_DATA_AGENT_PORTAL:'mailto:jason.song@ey.com;huanhuan.yue@ey.com;abel.sabu1@ey.com;karthik.ramanathan1@gds.ey.com?subject=Request for Access to GPO Agent Portal&body=Dear Admin,%0A%09Kindly provide access to the GPO Agent Portal application.'
 };
